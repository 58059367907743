export const steps = [
    {
        text: 'Sign Up or Login',
        icon: 'mdi-account-tie'
    },
    {
        text: 'Enroll to a Course',
        icon: 'mdi-cursor-default-click-outline'
    },
    {
        text: 'Proceed to Payment',
        icon: 'mdi-credit-card-outline'
    },
    {
        text: 'Finish a Course',
        icon: 'mdi-note-check-outline'
    },
    {
        text: 'Earn Certifications',
        icon: 'mdi-certificate-outline'
    },
]